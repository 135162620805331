/**
 * @fileoverview gRPC-Web generated client stub for address
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/address/address.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_address_address_pb from '../../protobuf/address/address_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';


export class AddressServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/address.AddressService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_address_address_pb.CreateAddressRequest,
    protobuf_model_merged_pb.AddressResponse,
    (request: protobuf_address_address_pb.CreateAddressRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.AddressResponse.deserializeBinary
  );

  create(
    request: protobuf_address_address_pb.CreateAddressRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.AddressResponse>;

  create(
    request: protobuf_address_address_pb.CreateAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.AddressResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.AddressResponse>;

  create(
    request: protobuf_address_address_pb.CreateAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.AddressResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/address.AddressService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/address.AddressService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/address.AddressService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_address_address_pb.UpdateAddressRequest,
    protobuf_model_merged_pb.AddressResponse,
    (request: protobuf_address_address_pb.UpdateAddressRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.AddressResponse.deserializeBinary
  );

  update(
    request: protobuf_address_address_pb.UpdateAddressRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.AddressResponse>;

  update(
    request: protobuf_address_address_pb.UpdateAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.AddressResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.AddressResponse>;

  update(
    request: protobuf_address_address_pb.UpdateAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.AddressResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/address.AddressService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/address.AddressService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorUpsertByPlaceID = new grpcWeb.MethodDescriptor(
    '/address.AddressService/UpsertByPlaceID',
    grpcWeb.MethodType.UNARY,
    protobuf_address_address_pb.UpsertAddressRequest,
    protobuf_model_merged_pb.AddressResponse,
    (request: protobuf_address_address_pb.UpsertAddressRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.AddressResponse.deserializeBinary
  );

  upsertByPlaceID(
    request: protobuf_address_address_pb.UpsertAddressRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.AddressResponse>;

  upsertByPlaceID(
    request: protobuf_address_address_pb.UpsertAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.AddressResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.AddressResponse>;

  upsertByPlaceID(
    request: protobuf_address_address_pb.UpsertAddressRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.AddressResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/address.AddressService/UpsertByPlaceID',
        request,
        metadata || {},
        this.methodDescriptorUpsertByPlaceID,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/address.AddressService/UpsertByPlaceID',
    request,
    metadata || {},
    this.methodDescriptorUpsertByPlaceID);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/address.AddressService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_address_address_pb.GetAddressesRequest,
    protobuf_address_address_pb.GetAddressesResponse,
    (request: protobuf_address_address_pb.GetAddressesRequest) => {
      return request.serializeBinary();
    },
    protobuf_address_address_pb.GetAddressesResponse.deserializeBinary
  );

  get(
    request: protobuf_address_address_pb.GetAddressesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_address_address_pb.GetAddressesResponse>;

  get(
    request: protobuf_address_address_pb.GetAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.GetAddressesResponse) => void): grpcWeb.ClientReadableStream<protobuf_address_address_pb.GetAddressesResponse>;

  get(
    request: protobuf_address_address_pb.GetAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.GetAddressesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/address.AddressService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/address.AddressService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorDelete = new grpcWeb.MethodDescriptor(
    '/address.AddressService/Delete',
    grpcWeb.MethodType.UNARY,
    protobuf_address_address_pb.DeleteAddressesRequest,
    protobuf_address_address_pb.DeleteAddressesResponse,
    (request: protobuf_address_address_pb.DeleteAddressesRequest) => {
      return request.serializeBinary();
    },
    protobuf_address_address_pb.DeleteAddressesResponse.deserializeBinary
  );

  delete(
    request: protobuf_address_address_pb.DeleteAddressesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_address_address_pb.DeleteAddressesResponse>;

  delete(
    request: protobuf_address_address_pb.DeleteAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.DeleteAddressesResponse) => void): grpcWeb.ClientReadableStream<protobuf_address_address_pb.DeleteAddressesResponse>;

  delete(
    request: protobuf_address_address_pb.DeleteAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.DeleteAddressesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/address.AddressService/Delete',
        request,
        metadata || {},
        this.methodDescriptorDelete,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/address.AddressService/Delete',
    request,
    metadata || {},
    this.methodDescriptorDelete);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/address.AddressService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_address_address_pb.ListAddressesRequest,
    protobuf_address_address_pb.ListAddressesResponse,
    (request: protobuf_address_address_pb.ListAddressesRequest) => {
      return request.serializeBinary();
    },
    protobuf_address_address_pb.ListAddressesResponse.deserializeBinary
  );

  list(
    request: protobuf_address_address_pb.ListAddressesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_address_address_pb.ListAddressesResponse>;

  list(
    request: protobuf_address_address_pb.ListAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.ListAddressesResponse) => void): grpcWeb.ClientReadableStream<protobuf_address_address_pb.ListAddressesResponse>;

  list(
    request: protobuf_address_address_pb.ListAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.ListAddressesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/address.AddressService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/address.AddressService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/address.AddressService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_address_address_pb.ExportAddressesRequest,
    protobuf_address_address_pb.ExportAddressesResponse,
    (request: protobuf_address_address_pb.ExportAddressesRequest) => {
      return request.serializeBinary();
    },
    protobuf_address_address_pb.ExportAddressesResponse.deserializeBinary
  );

  export(
    request: protobuf_address_address_pb.ExportAddressesRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_address_address_pb.ExportAddressesResponse>;

  export(
    request: protobuf_address_address_pb.ExportAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.ExportAddressesResponse) => void): grpcWeb.ClientReadableStream<protobuf_address_address_pb.ExportAddressesResponse>;

  export(
    request: protobuf_address_address_pb.ExportAddressesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_address_address_pb.ExportAddressesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/address.AddressService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/address.AddressService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

}

